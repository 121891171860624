@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
body {
	background-color: $bg-color;
}
header {
	background-color: $white;
}
h1, h2 {
	position: relative;
	padding-bottom: 12px;
	margin-bottom: 20px;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 130px;
		height: 5px;
		background-color: $orange;
	}
}
.underline {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 130px;
		height: 5px;
		background-color: $orange;
	}
}
.underline-center {
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: calc(50% - 40px);
		width: 80px;
		height: 3px;
		background-color: $orange;
	}
}
.header_san-nav {
	height: 60px;
	background-color: #fafafa;
	border-bottom: 1px solid #ebebeb;
}
.header_san-logo {
	height: 86px;
}
.logo-top {
	width: 100%;
	max-width: 220px;
	height: auto;
	margin-right: 15px;
}
.logo-comment-top {
	line-height: 21px;
	font-size: 15px;
}
.header_san-btn {
	flex-direction:column;
	justify-content: center;
	align-items: flex-end;
}
.header-main-menu {
	background-color: #fafafa;
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
	.navbar {
		padding: 0;
	}
	.navbar-nav {
		justify-content: space-between;
		width: 100%;
		.nav-link {
			padding: 0.7rem 1rem;
			color: $link;
			text-transform: uppercase;
			&:hover {
				background-color: #f4f4f4;
			}
		}
	}
}
.san-icon {
	height: 60px;
	align-items:center;
}
.san-main-contact {
	flex-direction:column;
	text-align: right;
	justify-content:center;
	.san-main-contact_tel {
		font-size: 21px;
	}
	a {
		font-size: 17px;
		line-height: 19px;
	}
}
.san-btn-nav {
	height: 40px;
	width: 190px;
	font-size: 13px;
	height: 42px;
	border-radius: 21px;
	background-color: $white;
	border: 1px solid #ebebeb;
	transition: 0.2s;
	&:hover {
		border-color: $link;
	}
	a {
		display: inline-block;
		padding-top: 3px;
		width: calc(100% - 40px);
		line-height: 16px;
		text-align: center;
		strong {
		text-transform: uppercase;
		}
	}
}
.slider-main {
	position: relative;
	&:after {
		content: '';
		height: 71px;
		width: 100%;
		background: url(../img/src/slider-wave.png) no-repeat center;
		background-size: cover;
		position: absolute;
		bottom: 0;
	}
	.carousel-indicators {
		bottom: 50px;
		.active {
			background-color: $orange;
		}
		li {
			height: 5px;
		}
	}
	.carousel-item {
		width: 100%;
		height: 670px;
		overflow: hidden;
		.carousel-inner {
			position: relative;
		}
		.carousel-caption {
			text-align: left;
			display: inline-block;
			background-color: rgba(0,0,0,0.40);
			bottom: 40%;
			width: fit-content;
			padding: 0 30px 0 0;
			&:before {
				content: '';
				background-color: rgba(0,0,0,0.40);
				position: absolute;
				left: -10000px;
				width: 10000px;
				height: 100%;
			}
			h5 {
				padding: 12px 0 10px;
				font-size: 42px;
				line-height: 42px;
				text-transform: uppercase;
			}
			p {
				margin-bottom: 10px;
			}
			a {
				padding: 12px 25px;
				background-color: rgba(255,255,255,0.7);
				color: #262626;
				display: inline-block;
				margin-bottom: 15px;
				transition: 0.2s;
				&:hover {
					text-decoration: none;
					background-color: rgba(255,255,255,1);
					box-shadow: 5px 5px 0 $orange;
				}
			}
		}
	}
}
.slider-wave {
	margin-top: -71px;
	position: relative;
	z-index: 100;
	height: 71px;
	width: 100%;
	background: url(../src/slider-wave.png) no-repeat center;
}
.news-block {
	transition:0.2s;
	&:hover {
		.news-image {
			box-shadow: 5px 5px 0 #ebebeb;
		}
	}
	.news-image {
		border-radius: 5px;
		overflow: hidden;
		margin-bottom: 10px;
		height: 240px;
	}
	h5 {
		font-size: 18px;
		a {
			color: #272727;
			&:hover {
				color: $link;
			}
		}
	}
}
.sanatory-promo {
	// background: url(../img/src/sanatory-promo-bg.jpg) repeat-x 50% 50%;
	.gull-title {
		font-size: 28px;
		padding-top: 40px;
		padding-bottom: 20px;
		margin-bottom: 15px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 10px;
			width: 60px;
			height: 37px;
			background: url(../img/src/birds-title.png) no-repeat top left;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 230px;
			height: 11px;
			background: url(../img/src/wave-underline.png) repeat-x;
		}
	}
	.promo-san-image {
		max-width: 100%;
		height: auto;
		img {
			width: 100%;
			height: 100%;
			object-fit:cover;
		}
	}
	ul {
		padding-left: 17px;
		li {
			list-style: url(../img/src/list-circle.png);
			margin-bottom: 7px;
		}
	}
}
.room {
	border-radius: 5px;
	&:hover {
		box-shadow: $shadow-big;
	}
	.room_img {
		border-radius: 5px 5px 0 0;
		height: 215px;
		width: 100%;
		overflow: hidden;
	}
	.room_text {
		padding: 0 10px 10px 10px;
		border: 1px solid #ebebeb;
		border-radius: 0 0 5px 5px;
		background-color: $white;
		.room_sanatory {
			padding-top: 10px;
			font-size: 13px;
			color: #555;
			position: relative;
			text-transform: uppercase;
			p {
				padding-bottom: 7px;
				margin-bottom: 7px;
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 80px;
					height: 2px;
					background-color: #ccc;
				}
			}
		}
		.room_name {
			h3 {
				font-size: 15px;
				text-transform: uppercase;
				height: 60px;
				line-height: 21px;
				overflow: hidden;
				a {
					color: #555;
					&:hover {
						color: $orange;
						text-decoration: none;
					}
				}
			}
		}
		.room_price {
			justify-content: space-between;
			vertical-align: center;
			font-size: 18px;
			.btn-blue {
				font-size: 15px;
				font-weight: normal;
				line-height: 28px;
				&:hover {
					background-color: #0075c4 !important;
				}
			}
		}
	}
}
.footer_logo-line {
	background-color: #fafafa;
	padding: 20px 0;
	margin-top: 40px;
	box-shadow: $shadow-big;
	.btn-32 {
		width: 100%;
	}
	.btn-grey {
		background-color: #acacac;
		color: $white;
		text-transform: uppercase;
	}
}
.footer_map-address {
	padding: 30px 0;
	background: rgb(237,244,248);
background: -moz-linear-gradient(45deg,  rgba(237,244,248,1) 1%, rgba(206,223,235,1) 100%);
background: -webkit-linear-gradient(45deg,  rgba(237,244,248,1) 1%,rgba(206,223,235,1) 100%);
background: linear-gradient(45deg,  rgba(237,244,248,1) 1%,rgba(206,223,235,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edf4f8', endColorstr='#cedfeb',GradientType=1 );
}
.footer-menu {
	li {
		display: inline-block;
		text-transform: uppercase;
		margin: 10px 15px;
	}
}
.social-footer {
	padding-left: 0;
	li {
		display: inline-block;
		margin-right: 10px;
		img {
			width: 24px;
			height: 24px;
		}
	}
}
.address {
	overflow: hidden;
	box-shadow: $shadow-big;
	height: 100%;
	.address_map {
		height: 180px;
		width: 100%;
		overflow: hidden;
	}
	.address_text {
		height: 100%;
		padding: 15px;
		text-align: center;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
		background-size: cover;
		h4 {
			padding-bottom: 10px;
			font-size: 18px;
		}
		p {
			margin-bottom: 0;
		}
	}
	.address_text-bg-gorniy {
		background-image: url(../img/src/map-gorniy-bg.jpg);
	}
	.address_text-bg-orlinoe {
		background-image: url(../img/src/map-orlinoe-bg.jpg);
	}
	.address_text-bg-primorie {
		background-image: url(../img/src/map-primorie-bg.jpg);
	}
}
.filter {
	padding: 20px 0;
	font-size: 14px;
}
.filter li {
	display: inline-block;
	margin-bottom: 15px;
	margin-right: 10px;
}
.filter li a {
	display: block;
	padding: 7px 20px;
	background-color: #ebebeb;
	border-radius: 7px;
	color: $main-color;
	text-transform: uppercase;
	&:hover {
		background-color: #dddddd;
		text-decoration: none;
		color: #000;
	}
}
.filter .active {
	background-color: $orange !important;
	color: $white;
}
.banner-warning {
	min-height: 140px;
	border: 2px solid #b2251b;
	border-radius: 5px;
	color: #555;
	background: url(../img/src/warning.jpg) 30px 50% no-repeat;
	align-items: center;
	padding-left: 140px;
	padding-right: 30px;
	background-color: $white;
	h4 {
		color: #b2251b;
		font-size: 36px;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.banner-warning_btn {
		margin-left: 40px;
	}
	.btn-50 {
		background-color: #fdeab2;
		border-radius: 5px;
		width: 340px;
		transition: 0.2s;
		&:hover {
			background-color: #ffdc74;
		}
	}
}
.gallery-room {
	.gallery-room__main-img {
		width: 100%;
		height: 380px;
		overflow: hidden;
		border-radius: 5px;
	}
	.gallery-room__sm-img {
		.photo-wrap {
			max-width: 20%;
			height: 100px;
		}
	}
}
.room_sananory-name {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	padding-bottom: 20px;
	margin-bottom: 15px;
	color: #555;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 230px;
		height: 11px;
		background: url(../img/src/wave-underline.png) repeat-x;
	}
}
.room-main-info {
	h1 {
		font-size: 32px;
		margin-bottom: 0;
		&:after {
			display: none;
		}
	}
}
.room-price {
	//background-color: #eef4f8;
	background-color: $white;
	border: 1px solid #e8f5fe;
	border-radius: 5px;
	padding: 15px;
	ul {
		padding-left: 0;
		width: 100%;
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid $bg-color;
			padding-bottom: 7px;
			margin-bottom: 7px;
			&:last-child {
				border-bottom: none;
			}
		}
	}
	.left {
		span {
			font-size: 13px;
		}
	}
	.btn-blue {
		text-transform: uppercase;
	}
}
.room-specifications {
	color: #555;
	ul {
		padding-left: 0;
		li {
			list-style: none;
			margin: 12px 0;
			&:before {
				content: '—';
				margin-right: 5px;
			}
		}
	}
}
.about-san-wide {
	padding: 20px 0;
	//background-color: #eef4f8;
	//background-image: url(../img/src/sananatory-about-wide-bg.jpg);
	background-color: $white;
	border-top: 1px solid #aeddfd;
	border-bottom: 1px solid #aeddfd;
	background-position: right bottom;
	background-repeat: no-repeat;
	.about-san-wide__image {
		width: 240px;
		height: 240px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 20px;
	}
	.about-san-wide__text {
		p {
			font-size: 21px;
		}
	}
}
.sanatory-mobile-contacts {
	display: none;
}
.modal-content {
	background: rgb(160,216,239);
background: -moz-linear-gradient(top,  rgba(160,216,239,1) 0%, rgba(221,241,249,1) 65%, rgba(254,255,255,1) 100%);
background: -webkit-linear-gradient(top,  rgba(160,216,239,1) 0%,rgba(221,241,249,1) 65%,rgba(254,255,255,1) 100%);
background: linear-gradient(to bottom,  rgba(160,216,239,1) 0%,rgba(221,241,249,1) 65%,rgba(254,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0d8ef', endColorstr='#feffff',GradientType=0 );

}
.big-price-table {
	line-height: 1;
	font-size: 13px;
	width: 100%;
	td {
		border: 1px solid #ccc;
		text-align: center;
		vertical-align: middle;
	}
	.rotate {
		span {
			font-weight: 700;
			writing-mode: tb;
			transform: rotate(180deg);
		}
	}
}
@media (max-width: 1366px) {

}
@media (max-width: 1200px) {
}
@media (max-width: 1024px) {

}
@media (max-width: 992px) {
.banner-warning {
	.btn-50 {
		width: 280px;
		}
	}
	.promo-san-image {
		display: none;
	}
	.san-icon {
		font-size: 13px;
	}
	.san-main-contact {
		.san-main-contact_tel {
			font-size: 18px;
		}
	}
	.room {
		.room_text {
			.room_sanatory {
				font-size: 12px;
			}
			.room_name {
				h3 {
					font-size: 14px;
					line-height: 19px;
				}
			}
			.room_price {
				.btn-blue {
					font-size: 14px;
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
	}
}
@media (max-width: 768px) {
	.carousel-caption {
		display: block !important;
	}
	.san-btn-nav {
		width: 100%;
	}
	.header_san-nav {
		height: auto;
	}
	.footer_logo-line {
		.header_san-btn {
			align-items: flex-start;
		}
		.btn-32 {
			display: block;
			width: 240px;
		}
	}
}
@media (max-width: 720px) {
	.banner-warning {
		flex-direction: column;
		align-items: flex-start;
		padding-top: 15px;
		padding-bottom: 15px;
		.banner-warning_btn {
			margin-left: 0;
			margin-top: 15px;
			width: 100%;
			.btn-50 {
				width: 100%;
				}
		}
	}
	.header_san-logo {
		flex-direction:column !important;
		align-items: flex-start !important;
	}
	.header_san-logo {
		height: auto;
	}
	.logo-comment-top {
		font-size: 13px;
		line-height: 18px;
	}
}
@media (max-width: 576px) {
	.sanatory-mobile-contacts {
		display: block;
		text-align: right;
	}
	.san-main-contact {
		display: none !important;
	}
	.san-icon:nth-child(1) {
		padding-right: 5px;
	}
	.san-icon:nth-child(2) {
		padding-right: 5px;
		padding-left: 5px;
	}
	.san-icon:nth-child(3) {
		padding-left: 5px;
	}
	.san-btn-nav {
		span {
			display: none;
		}
		a {
			font-size: 12px;
			padding-top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.header_san-btn {
		.btn-blue {
			padding: 0 15px;
		}
	}
	.header_san-logo {
		padding-top: 10px;
	}
	.logo-top {
		max-width: 180px;
	}
	.slider-main {
		.carousel-item {
			height: 360px;
			.carousel-caption {
				h5 {
					font-size: 28px;
					line-height: 30px;
				}
				a {
					padding: 7px 25px;
				}
			}
		}
	}
	.slider-wave {
		display: none;
	}
	.gallery-room {
		.gallery-room__main-img {
		}
		.gallery-room__sm-img {
			.photo-wrap {
				max-width: 32%;
				height: 100px;
			}
		}
	}
	.about-san-wide {
		background-image: none;
		.about-san-wide__text {
			p {
				font-size: 16px;
			}
		}
		.about-san-wide__image {
			width: 80px;
			height: 80px;
		}
	}
}
@media (max-width: 414px) {
	.gallery-room {
		.gallery-room__main-img {
			height: 260px;
		}
		.gallery-room__sm-img {
			.photo-wrap {
				max-width: 32%;
				height: 100px;
			}
		}
	}
	.banner-warning {
		background-position: 10px 50%;
		padding-left: 110px;
		padding-right: 15px;
		h4 {
			font-size: 26px;
		}
	}
}
@media (max-width: 411px) {
	.san-btn-nav {
		img {
			display: none;
		}
		a {
			width: 100%;
		}
	}
	.logo-top {
		max-width: 150px;
	}
	.header_san-btn {
		padding-top: 10px;
		justify-content: flex-start;
		.btn-blue {
			font-size: 13px;
		}
	}
	.sanatory-mobile-contacts {
		font-size: 14px;
	}
}
@media (max-width: 400px) {

}
@media (max-width: 375px) {

}
@media (max-width: 360px) {

}
@media (max-width: 320px) {

}